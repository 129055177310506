<template>
  <v-container fluid>
    <v-row justify="center" align="center" class="h-100">
      <v-col cols="12" sm="10" md="6" lg="4">
        <v-img src="@/assets/images/logo.png" max-height="150" contain></v-img>

        <v-card>
          <v-card-title class="text-center"> </v-card-title>
          <v-card-text>
            <h2 class="headline text-center">{{ isSignup ? 'Join Zuzy.in' : 'Welcome Back to Zuzy.in' }}</h2>

            <div class="subtitle-1 text-center">
              {{ isSignup ? 'Create your account' : 'Login to your account' }}
            </div>
            <v-form v-if="!isSignup" @submit.prevent="login">
              <v-text-field v-model="email" label="Email" required></v-text-field>
              <v-text-field v-model="password" label="Password" type="password" required></v-text-field>
              <v-btn type="submit" color="primary" block>Login</v-btn>
            </v-form>
            <v-form v-else @submit.prevent="signup">
              <v-text-field v-model="name" label="Name" required></v-text-field>
              <v-text-field v-model="email" label="Email" required></v-text-field>
              <v-text-field v-model="password" label="Password" type="password" required></v-text-field>
              <v-text-field v-model="phoneNumber" label="Phone Number" required></v-text-field>
              <v-btn type="submit" color="primary" block>Sign Up</v-btn>
            </v-form>
            <div class="text-center mt-4">
              <a href="#" @click="toggleForm">{{ isSignup ? 'Login' : 'Sign Up' }}</a>
            </div>
          </v-card-text>
          <v-card-actions class="justify-center"> </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <span>&copy; 2024 | Developed with 💚 by Hecto Team</span>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      isSignup: false,
      name: '',
      email: '',
      password: '',
      phoneNumber: '',
    }
  },
  methods: {
    ...mapActions(['fetchUser']),
    async login() {
      try {
        const response = await axios.post('https://api.zuzy.in/login', {
          email: this.email,
          password: this.password,
        })
        console.log('Login successful:', response.data)

        const userData = response.data.user
        const accessToken = response.data.accessToken
        localStorage.setItem('user', JSON.stringify(userData))
        localStorage.setItem('accessToken', accessToken)
        this.$router.push('/dashboard')

        // Optionally, redirect to another page after successful login
      } catch (error) {
        console.error('Login failed:', error)
        // Show error message to the user
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Login Failed, Check email or password! Please contact 7016241044 for assistance.',
        })
      }
    },
    async signup() {
      try {
        const response = await axios.post('https://api.zuzy.in/signup', {
          name: this.name,
          email: this.email,
          phone: this.phoneNumber,
          password: this.password,
        })
        const userData = response.data.user
        const accessToken = response.data.accessToken
        localStorage.setItem('user', JSON.stringify(userData))
        localStorage.setItem('accessToken', accessToken)

        // Set user data and login status in Vuex store
        this.$store.commit('SET_USER', userData)
        this.$store.commit('SET_ACCESS_TOKEN', accessToken)
        this.$store.commit('SET_LOGGED_IN', true)
        console.log('Signup successful:', response.data)
        this.$store.commit('SET_USER', response.data.user)
        this.$store.commit('SET_LOGGED_IN', true)
        // Optionally, redirect to another page after successful signup
      } catch (error) {
        console.error('Signup failed:', error)
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'User already exits! Please contact 7016241044 for assistance.',
        })
        // Show error message to the user
      }
    },
    toggleForm() {
      this.isSignup = !this.isSignup
    },
  },
}
</script>

<style scoped>
.h-100 {
  height: 100vh;
}
</style>
